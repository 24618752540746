import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c1e4d4ef"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "control-input" }
const _hoisted_3 = ["type", "name", "id", "value", "placeholder", "required"]
const _hoisted_4 = { class: "suffix-icon" }
const _hoisted_5 = {
  key: 1,
  class: "fa fa-times-circle",
  style: {"color":"var(--red-500)"}
}
const _hoisted_6 = {
  key: 2,
  class: "fa fa-check-circle",
  style: {"color":"var(--green-500)"}
}
const _hoisted_7 = {
  key: 1,
  class: "input-error"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Loading = _resolveComponent("Loading")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["form-group frappe-control", this.hasError && 'has-error'])
  }, [
    ($props.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: $props.name,
          class: _normalizeClass(["control-label", $props.required && 'reqd'])
        }, _toDisplayString($props.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_1))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_2, [
      (['text', 'email'].includes($props.inputType))
        ? (_openBlock(), _createElementBlock("input", {
            key: 0,
            type: $props.inputType || 'text',
            name: $props.name,
            id: $props.name,
            class: _normalizeClass(["form-control", $props.inputClass]),
            value: $props.modelValue,
            placeholder: $props.placeholder,
            required: $props.required,
            onInput: _cache[0] || (_cache[0] = $event => (_ctx.$emit('update:modelValue', $event.target.value))),
            onBlur: _cache[1] || (_cache[1] = $event => (_ctx.$emit('blur', $event.target.value.trim())))
          }, null, 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_3))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_4, [
        ($options.isLoading)
          ? (_openBlock(), _createBlock(_component_Loading, {
              key: 0,
              radius: "15",
              color: "var(--text-light)",
              stroke: "1.8"
            }))
          : ($options.hasError)
            ? (_openBlock(), _createElementBlock("i", _hoisted_5))
            : ($options.isValid)
              ? (_openBlock(), _createElementBlock("i", _hoisted_6))
              : _createCommentVNode("v-if", true)
      ])
    ]),
    ($props.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString($props.error), 1 /* TEXT */))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}