import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-47b7092c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (!$data.hideMessage)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["form-message", $props.color])
          }, [
            _createElementVNode("span", { innerHTML: $props.message }, null, 8 /* PROPS */, _hoisted_1),
            _createElementVNode("span", {
              class: "close",
              onClick: _cache[0] || (_cache[0] = (...args) => ($options.dismiss && $options.dismiss(...args)))
            }, "×")
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }))
}