import { resolveComponent as _resolveComponent, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57aa3135"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "india-compliance-account" }
const _hoisted_2 = { class: "content" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PreLoader = _resolveComponent("PreLoader")
  const _component_router_view = _resolveComponent("router-view")
  const _component_TheFooter = _resolveComponent("TheFooter")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ($data.isLoading)
        ? (_openBlock(), _createBlock(_Transition, {
            key: 0,
            name: "fade"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_PreLoader)
            ]),
            _: 1 /* STABLE */
          }))
        : (_openBlock(), _createBlock(_component_router_view, { key: 1 }, {
            default: _withCtx(({ Component }) => [
              _createVNode(_Transition, {
                name: "fade",
                mode: "out-in"
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */)
            ]),
            _: 1 /* STABLE */
          }))
    ]),
    _createVNode(_component_TheFooter)
  ]))
}