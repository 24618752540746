import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a311fd0e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_FormField = _resolveComponent("FormField")

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[2] || (_cache[2] = _withModifiers((...args) => ($options.submitAuthForm && $options.submitAuthForm(...args)), ["prevent"]))
  }, [
    _createVNode(_component_FormField, {
      "input-type": "email",
      name: "email",
      placeholder: "Email",
      modelValue: $data.email.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.email.value) = $event)),
      modelModifiers: { trim: true },
      required: true,
      error: $data.email.error,
      state: $data.email.state
    }, null, 8 /* PROPS */, ["modelValue", "error", "state"]),
    _createVNode(_Transition, { name: "slide" }, {
      default: _withCtx(() => [
        (!$props.isAccountRegistered)
          ? (_openBlock(), _createBlock(_component_FormField, {
              key: 0,
              "input-type": "text",
              name: "gstin",
              placeholder: "GSTIN",
              modelValue: $data.gstin.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.gstin.value) = $event)),
              modelModifiers: { trim: true },
              required: true,
              error: $data.gstin.error,
              state: $data.gstin.state
            }, null, 8 /* PROPS */, ["modelValue", "error", "state"]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("button", {
      class: "btn btn-primary btn-sm btn-block",
      disabled: $options.actionDisabled,
      type: "submit"
    }, _toDisplayString($options.computedSubmitLabel), 9 /* TEXT, PROPS */, _hoisted_1),
    ($data.error)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          class: "server-error",
          innerHTML: $data.error
        }, null, 8 /* PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true)
  ], 32 /* NEED_HYDRATION */))
}